.loginBox .password-box .ant-input-affix-wrapper {
  padding: 0;
}
.loginBox .password-box .ant-input {
  padding: 3.4px 11px!important;
}
.loginBox .password-box .ant-input-suffix {
  position: absolute;
  top: 50%;
  right: 11px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
