.customSaveBtn {
  background: linear-gradient(45deg, #e62878, #786eaf 33%, #e62878 66%, #786eaf 99%, #e62878 132%, #786eaf 165%);
  background-size: 610% 610%;
  width: 150px;
  color: #fff;
  border: none;
}
.customSaveBtn:hover,
.customSaveBtn:active,
.customSaveBtn:focus {
  background: linear-gradient(45deg, #e62878, #786eaf 33%, #e62878 66%, #786eaf 99%, #e62878 132%, #786eaf 165%);
  background-size: 300% 300%;
  color: #fff;
  text-decoration: underline;
}
