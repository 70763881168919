.main {
  display: flex;
  align-items: center;
}
.main .box {
  position: relative;
  flex-shrink: 0;
  width: 367px;
  height: 213px;
  background: #FFFFFF;
  border-radius: 10px;
  overflow: hidden;
}
.main .box + .box {
  margin-left: 38px;
}
.main .circle {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 19px;
  left: 21px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
}
.main .title {
  margin: 50px 0 10px 42px;
  font-size: 22px;
  font-weight: 500;
  color: #333333;
}
.main .count {
  margin: 0 0 0 42px;
  font-size: 48px;
  font-weight: 500;
  color: #333333;
}
.main .big-circle {
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform: translate(45%, 45%);
  transform: translate(45%, 45%);
  width: 145px;
  height: 145px;
  border-radius: 50%;
}
.main .small-circle {
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
  z-index: 2;
  width: 100px;
  height: 100px;
  opacity: 0.1;
  border-radius: 50%;
}
